html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --toastify-color-dark: #000;
  --whatsapp-primary: rgba(36, 211, 102, 1);
  --whatsapp-secondary: rgba(36, 211, 102, 0.2);
  --engage-primary: rgba(152, 160, 217, 1);
  --engage-secondary: rgba(152, 160, 217, 0.35);
  --chat-primary: rgba(88, 185, 183, 1);
  --chat-secondary: rgba(88, 185, 183, 0.4);
}

input.search {
  color: #fff;
}

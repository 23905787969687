.react-datepicker-wrapper {
  margin: 0 !important;
}

.react-datepicker-popper {
  --border: 1px solid rgba(255, 255, 255, 0.4);
  --bg: #1b1b27;
  --text: #fff;
  --hover: #767676;
  --selected: #767676;
}

.react-datepicker {
  background: var(--bg);
  color: var(--text);
  border: var(--border);
}

.react-datepicker__current-month {
  color: var(--text);
}

.react-datepicker__header {
  background: var(--bg);
  color: var(--text);
  border-bottom: var(--border);
}

.react-datepicker__day-name {
  color: var(--text);
}

.react-datepicker__day {
  color: var(--text);
}

.react-datepicker__day:hover {
  background: var(--hover);
}

.react-datepicker__day--selected {
  background: var(--selected);
}

.react-datepicker__time-container {
  border-left: var(--border);
}

.react-datepicker-time__header {
  color: var(--text);
}

.react-datepicker__time {
  background: var(--bg) !important;
  color: var(--text);
}

.react-datepicker__time-list-item {
  height: unset !important;
  padding: 8px 5px !important;
}

.react-datepicker__time-list-item:hover {
  background: var(--hover) !important;
}

.react-datepicker__time-list-item--selected {
  background: var(--selected) !important;
}
